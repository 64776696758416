$(document).ready(function() {
  $(".click-1").click(function() {
    $(".step-1").toggle(500);
    $(".step-1-a").toggle(500);
  });
  $(".click-2").click(function() {
    $(".step-2").toggle(500);
    $(".step-2-a").toggle(500);
  });
  $(".od-m").click(function() {
    var toggleText = $("#toggle-this").text();
  
    if (toggleText === "View") {
      $("#toggle-this").text("Close");
    } else {
      $("#toggle-this").text("View");
    }
    
    $(".od-items-m").toggle(500);
  });
  // To ensure and address is always selected
  $(".address-checkbox").click(function() {
    $(".address-checkbox").prop("checked", false);
    $(this).prop("checked", true);
  });
  $(".station-checkbox").click(function() {
    $(".station-checkbox").prop("checked", false);
    $(this).prop("checked", true);
  });
  $("#close-delivery-panel").click(function() {
    $(".step-2").hide(500);
    $(".step-2-a").hide(500);
    $("#complete-2").show(300);
    $(".step-3").show(500);
  });
  $(".click-3").click(function() {
    $(".step-3").toggle(500);
  });

  $("#listing_onsale").click(function() {
    if ($("#listing_onsale").prop("checked") == true) {
      $(".sale-price").show(200);
    } else {
      $(".sale-price").hide(200);
    }
  });
  $("#listing_onsale_multiple").change(function() {
    if ($("#listing_onsale_multiple").val() == "true") {
      $(".sale-price").show(200);
    } else {
      $(".sale-price").hide(200);
    }
  });
  if ($("#listing_onsale").prop("checked") == true) {
    $(".sale-price").show(200);
  }
  $("#featured_product_onsale").click(function() {
    if ($("#featured_product_onsale").prop("checked") == true) {
      $(".sale-price").show(200);
    } else {
      $(".sale-price").hide(200);
    }
  });
  if ($("#featured_product_onsale").prop("checked") == true) {
    $(".sale-price").show(200);
  }
  $("#sliderbutton").click(function() {
    $("#sliderbutton").hide(200);
    $(".form-hide1").show(200);
  });
  $("#sliderbuttonhide").click(function() {
    $(".form-hide1").hide(200);
    $("#sliderbutton").show(200);
  });
  $("#featuredbutton").click(function() {
    $("#featuredbutton").hide(200);
    $(".form-hide2").show(200);
  });
  $("#featuredbuttonhide").click(function() {
    $(".form-hide2").hide(200);
    $("#featuredbutton").show(200);
  });
  $(".category-checkbox").click(function() {
    $(".category-checkbox")
      .not(this)
      .prop("checked", false);
  });
  $(".admin-filter-button").click(function() {
    $(".admin-filters").toggle(500);
  });
  $(".follow-up-button").click(function() {
    $(".follow-up").toggle(500);
  });
  $("img, .lazyload").lazyload({
    failure_limit: Math.max(jQuery("img").length - 1, 0)
  });
  $(".arrow-slick2").on('click', function() {
      setTimeout(function() {
          $("img, .lazyload").lazyload({
              failure_limit: Math.max(jQuery("img").length - 1, 0)
          });
      }, 100);
  });

  $(".slick-slider").on('touchmove', function() {
    $("img, .lazyload").lazyload({
      failure_limit: Math.max(jQuery("img").length - 1, 0)
    });
  });
  $(".slick-slider").on('beforeChange', function() {
    $("img, .lazyload").lazyload({
      failure_limit: Math.max(jQuery("img").length - 1, 0)
    });
  });
  $(".slick-slider").on('afterChange', function() {
    $("img, .lazyload").lazyload({
      failure_limit: Math.max(jQuery("img").length - 1, 0)
    });
  });
  $(".magnify").click(function() {
    $(".big-im").toggle(200);
    $(".small-im").toggle(200);
  });
  $(".help-b").click(function() {
    var iconElement = $(this).find("i");
    iconElement.toggleClass("fa-whatsapp fa-close");
    $(".floating-buttons").toggle();
  });
  $(".actions").on("click", ".btn", function() {
    var $parentActions = $(this).closest(".actions");
    $(".submit", $parentActions).hide();
    $(".loading", $parentActions).show();
  });
  $("#update-stock-no").click(function() {
    $(".update-stock-no").toggle(200);
  });
  // Mannual Order Creation
  if ($("#order_user").val() != "") {
    $(".order-details-steps").show();
  }
  // Mannual Order Edit
  $(".user-dets").click(function()  {
    $(".order-details-form").toggle(200);
    $(".v-details").toggle(200);
  });;
  // FAQs section
  $(".question").click(function() {
    $(".content-f", this).toggle(100);
  });
  // THIS IS FOR WHEN TWO BUTTONS SIDE BY SIDE SHARE THE SAME CLASS NAMES
  $(".actions-custom").click(function() {
    $(".submit-custom").hide();
    $(".loading-custom").show();
  });
  $(".actions-custom-one").click(function() {
    if ($("#payment_code").val() == "") {
    } else if ($("#payment_amount_paid").val() == "") {
    } else {
      $(".submit", this).hide();
      $(".loading", this).show();
    }
  });
  $(".address-checkbox").click(function() {
    $(".address-checkbox").prop("checked", false);
    $(this).prop("checked", true);
  });
  $(".bulk-actions-button").click(function() {
    $(".bulk-actions-submit-buttons").toggle(300);
    $(".bulk-checkbox").toggle(300);
  });
  $(".view-selected").click(function() {
    $(".selected-listings").toggle(300);
  });
  $(".delete-multiple-listings-button").click(function() {
    $("#delete_mulitple").submit();
  });
  $("#select-all-checkbox").change(function() {
    if ($("#select-all-checkbox").prop("checked") == true) {
      $(".listing-checkbox").prop("checked", true);
    } else {
      $(".listing-checkbox").prop("checked", false);
    }
  });
  $(".promo-messages").slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: true,
  });
  $(".category-v3-slider").slick({
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  })
  $(".app-user-reviews").slick({
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
    dots: true,
    autoplaySpeed: 3000
  });
  $(".slick-admin").slick({
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
    dots: false,
    autoplaySpeed: 3000
  });
  $(".slick-admin-listings").slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  $("#resetSearch").click(function() {
    // Clear the input field when the <p> tag is clicked
    $("#searchlistings").val("");
  });
  $(".linked-slider").slick({
    slidesToShow: 2,
    autoplay: true,
    arrows: false,
    dots: false,
    autoplaySpeed: 3000
  });
  $(".analytics-edit-button").click(function() {
    $(".analytics-data").toggle(200);
    $(".data-form").toggle(200);
  });
  $(".cli").click(function() {
    $(".analytics-section").toggle(200);
  })
  $("#share-button")
    .popover()
    .click(function() {
      setTimeout(function() {
        $("#share-button").popover("hide");
      }, 1000);
    });
  $("#custom-phone-numbers").click(function() {
    $(".custom-phone-numbers").toggle(200);
  });
  $(function() {
    $('[data-toggle="tooltip"]').tooltip();
  });
  $(".js-select2").each(function () {
    $(this).select2({minimumResultsForSearch: 20, dropdownParent: $(this).next('.dropDownSelect2')});
  })

  $('.parallax100').parallax100();

  $('.gallery-lb').each(function () { // the containers for all your galleries
    $(this).magnificPopup({
      delegate: 'a', // the selector for gallery item
      type: 'image',
      gallery: {
        enabled: true
      },
      mainClass: 'mfp-fade'
    });
  });

  $('.js-addwish-b2').on('click', function (e) {
    e.preventDefault();
  });

  $('.js-addwish-b2').each(function () {
    var nameProduct = $(this).parent().parent().find('.js-name-b2').html();
    $(this).on('click', function () {
      swal(nameProduct, "is added to wishlist !", "success");

      $(this).addClass('js-addedwish-b2');
      $(this).off('click');
    });
  });

  $('.js-addwish-detail').each(function () {
    var nameProduct = $(this).parent().parent().parent().find('.js-name-detail').html();

    $(this).on('click', function () {
      swal(nameProduct, "is added to wishlist !", "success");

      $(this).addClass('js-addedwish-detail');
      $(this).off('click');
    });
  });

  /*---------------------------------------------*/

  $('.js-addcart-detail').each(function () {
    var nameProduct = $(this).parent().parent().parent().parent().find('.js-name-detail').html();
    $(this).on('click', function () {
      swal(nameProduct, "is added to cart !", "success");
    });
  });

  $('.js-pscroll').each(function () {
    $(this).css('position', 'relative');
    $(this).css('overflow', 'hidden');
    var ps = new PerfectScrollbar(this, {
      wheelSpeed: 1,
      scrollingThreshold: 1000,
      wheelPropagation: false
    });

    $(window).on('resize', function () {
      ps.update();
    })
  });
});
